import React from "react";
import Button from '@mui/material/Button';
import cn from "../css/button.module.scss"

const PrimaryButton = (props) => {

  // let size = "nomal"
  if (props.size === "small") {
    return (
      <Button
        className={`${cn.button} ${cn.small}`}
        variant="contained"
        onClick={() => props.onClick()}
      >
        {props.label}
      </Button>
    );
  }
  // console.log("disabled flag:"+props.disabled)

  let flagDisabled = false;
  if (props.disabled) {
    flagDisabled = true;
  }

  return (
    <>
      {flagDisabled ? (
        <Button className={`${cn.button} ${cn.disabled}`} variant="contained">
          {props.label}
        </Button>
      ) : (
        <Button
          className={`${cn.button} ${cn.active}`}
          variant="contained"
          onClick={() => props.onClick()}
        >
          {props.label}
        </Button>
      )}
    </>
  );
};

export default PrimaryButton;
