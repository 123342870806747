import {createSelector} from 'reselect';

const interactivesSelector = (state) => state.interactives;

export const getInteractives = createSelector(
    [interactivesSelector],
    state => state.list
)

export const getIntId = createSelector(
    [interactivesSelector],
    state => state.intId
)

export const getContentId = createSelector(
    [interactivesSelector],
    state => state.contentId
)

