import React, { useState, useEffect, memo, useRef } from "react";
import cn from "./form.module.scss";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";

const FormControls = styled(FormControl)`
  root: {
    color: #fff;
  }
  .MuiInputLabel-root {
    // background-color: rgb(255 255 255 / 0.7);
    // &.Mui-focused {
    //   color: #b00088;
    //   background-color: rgb(255 255 255 / 0.7);
    // }
  }
  .MuiFilledInput-root {
    background-color: rgb(255 255 255 / 0.2);
    :before {
      border-bottom: 1px solid #fff;
      border-image-repeat: stretch;
    }
    :after {
      border-bottom: 1px solid #fff;
    }
    :hover {
      // background-color: rgb(255 255 255 / 0.7);
    }
    &.Mui-focused {
      // background-color: rgb(255 255 255 / 0.7);
    }
  }
  .MuiSvgIcon-root{
    color:#fff;
  }
  .MuiFilledInput-input {
    color:#fff;
    font-size: 0.8em;
    :focus {
      // background-color: rgb(255 255 255 / 0.7);
    }
  }
  .MuiSelect-root {
    background-color: rgb(255 255 255 / 0.7);
  }
  .MuiFormLabel-root {
    // background-color: rgb(255 255 255 / 0.7);
    color:#fff;
    font-size: 0.8em;
  }
`;

const DropdownInput = memo(({ id, value, setValue, setWidth, required, }) => {
  const [inputError, setInputError] = useState(false);

  const handleChange = () => {
    if (required && !value) {
      setInputError(true);
    } else {
      setInputError(false);
    }
  };

  const margin = setWidth && "mar-r05";
  return (
    <FormControls
      fullWidth={!setWidth}
      variant="filled"
      className={`${cn.boxWrap} ${margin}`}
    >
      <InputLabel id={id.id} htmlFor="uncontrolled-native">
        {id.label}
      </InputLabel>
      <Select
        native
        autoWidth
        onChange={(e) => {
          setValue(e.target.value), handleChange;
        }}
        onBlur={handleChange}
        error={inputError}
      >
        {(() => {
          if (value) {
            return (
              <option value={value}>
                {value}
              </option>
            );
          } else {
            return (
              <option aria-label="None" value="" />
            )
          }
        })()}

        {id.options.map((values, key) => (
          <option key={key} value={values.label}>
            {values.label}
          </option>
        ))}
      </Select>
      {(() => {
        if (inputError) {
          return (
            <FormHelperText className={cn.errorText}>
              必須項目です
            </FormHelperText>
          );
        }
      })()}
    </FormControls>
  );
});
export default DropdownInput;
