import React from "react";
import { PrimaryButton } from './index';
import { useDispatch } from 'react-redux';
import { strage } from '../../firebase/index';
import Modal from '@mui/material/Modal';
import movImage from '../../assets/img/src/bg_movie.svg';
import cn from "../css/utilityModal.module.scss"
import ReactPlayer from 'react-player'


function rand() {
	return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
	const top = 50 + rand();
	const left = 50 + rand();

	return {
		top: `${top}%`,
		left: `${left}%`,
		transform: `translate(-${top}%, -${left}%)`,
	};
}


const downloadImg = (fileURL) => {
	console.log(fileURL)
	var storageRef = strage.refFromURL(fileURL)
	storageRef.getDownloadURL().then(function (url) {
		// `url` is the download URL for 'images/stars.jpg'
		console.log(url)
		// This can be downloaded directly:
		var xhr = new XMLHttpRequest();
		xhr.responseType = 'blob';
		xhr.onload = function (event) {
			var blob = xhr.response;
			const url = URL.createObjectURL(blob);
			const a = document.createElement("a");
			document.body.appendChild(a);
			a.download = 'sporevo.jpg';
			a.href = url;
			a.click();
			a.remove();
			URL.revokeObjectURL(url);
		};
		xhr.open('GET', url);
		xhr.send();

		// Or inserted into an <img> element:
		// var img = document.getElementById('myimg');
		// img.src = url;
	}).catch(function (error) {
		// Handle any errors
		console.log(error)
	});
}



const ResultDetailCard = (props) => {
	const contentId = props.contentId
	const playDate = props.playDate
	const score = props.score
	const level = props.level
	const picUrl = props.picUrl
	const movUrl = props.movUrl
	const unit = props.unit
	// const cn = useStyles()
	const dispatch = useDispatch()
	let animal = ""
	let contTitle = ""
	let columnText = ""
	if (contentId === 'c10201') {
		contTitle = 'VERTICAL POWER JUMP'
		if (level == 1) {
			animal = "Puma";
			columnText = "高く遠く飛べるのは、後ろ足が前脚より長く強いため。また、時速80キロのスピードで走ることができ、長さでいうと10メートルは飛べる。しかし長距離には向いていないので、獲物は走って追うよりは、背後に忍び寄って飛びつくことで捕獲している。";
		}
		if (level == 2) {
			animal = "Orca";
			columnText = "海洋動物の場合、体の4割が水上に出たジャンプをブリーチングと呼ぶ。大量のエネルギーを要するので滅多に観察されない行為。主に狩の際、体についた寄生虫などを落とすため、遠くにいる仲間とコミュニケーションをとるなどのためにジャンプしている。";
		}
		if (level == 3) {
			animal = "Lion";
			columnText = "ネコ科の中でも高いパワーとスピードを備えており、特にジャンプ力には優れている。長距離を走るのには向いていないため、優れたジャンプ力で獲物を捕らえている。";
		}
		if (level == 4) {
			animal = "Kangaroo";
			columnText = "耐久性の高いジャンプ：食料や水の資源がある何百キロも離れた広いへき地を、何時間もジャンプし続けられる。人間のように筋肉のみに頼らず、足の腱がバネのようにストレッチする仕組みになっていて、エネルギーを効率よく吸収し放出しながらジャンプをしている。";
		}
		if (level == 5) {
			animal = "Wolf";
			columnText = "一日に200km程を移動するとされていて、通常の移動はやや早足で行われる。ネコ科ほどは跳躍力に優れていないが、獲物を追うときなどは55～70km/hで走ることができ、20分も追い続けることができる。";
		}
		if (level == 6) {
			animal = "Horse";
			columnText = "馬は自分の肩甲骨の高さは飛び越えられるとされているが、訓練を受ければさらに高く飛べる。中でもサラブレッドやアラブ種などはジャンプに特化している。ジャンプを競う馬術競技を「障害飛越競技」とよび、かつては狩猟を行っていた者が技量比べとして行っていたものだったが、ルールが進歩して今はオリンピックなどにも採用されている競技である。ジャンプの高さの世界記録は2.47メートル。";
		}
		if (level == 7) {
			animal = "Rabbit";
			columnText = "後ろ足の筋肉がとても発達している。また、かかとが大きいため、地面に接する面積が広い分、蹴る力が最大限に発揮される。また、ジャンプだけでなく、種類によっては時速70キロものスピードで走ることができると言われている。";
		}
		if (level == 8) {
			animal = "Cat";
			columnText = "先祖は木の上で待ち伏せしたり、木から木へと飛び移ったりして獲物を狩っていた。跳躍力は、およそ体高の5倍程度。関節、筋肉、靭帯の柔軟性が非常に高く、後ろ足の筋肉が発達しているため、瞬間的に高くジャンプしたり、着地した時の衝撃を吸収することができる。";
		}
		if (level == 9) {
			animal = "Hawk";
			columnText = "開長時130cmにもなる両翼を持つ鷹。その大きな翼を使い、螺旋状に巻きあがる上昇気流に乗って高さ1000m級の高度を滑空する。上昇気流がない時間は両翼で力強く羽ばたき、空に舞い上がる。";
		}
	} else if (contentId === 'c10202') {
		contTitle = 'FULL BODY PRESS'
		if (level == 1) {
			animal = "Elephant";
			columnText = "陸上の哺乳類としては最大の大きさを誇るゾウ。大きいものは７トンもの体重がある。巨体のために1日の食事量は150kg必要となることもある。象の鼻は100〜200kgの丸太を運ぶことができると言われ、押す場合はそれ以上の重さが可能。乗用車であれば鼻と顔を使って持ち上げることもできると言われる。アジアゾウは何千年も前から荷物や人を運ぶ役割として飼われてきた。";
		}
		if (level == 2) {
			animal = "Hippopotamus";
			columnText = "重さはおよそ3トン。陸上動物としてはゾウとサイに次ぐ3番目の重さとされる。口は150度にも開き、噛む力は１トン。人間の噛む力の11倍はある。野生のカバは獰猛であり、ライオンやワニ、そして他の縄張りのカバであっても自分の縄張りに侵入したものは攻撃することがある。";
		}
		if (level == 3) {
			animal = "Rhino";
			columnText = "陸上動物としてはゾウに次ぐ２番目の重さを誇る。体高1.8メートルに対し2.5トンもの体重を超える。巨体、鋭いツノ、そして丈夫な皮膚を備えているため、野生には天敵が存在しない。危険を察知したら逃げるのではなく、対象に向かって突進して威嚇する習性がある。";
		}
		if (level == 4) {
			animal = "Bear";
			columnText = "人間より2.5〜5倍も強いと言われる。身長は3メートルほどにもおよび、体重の約8割、つまりおよそ500キロを運ぶこともできる。餌を探すために重い岩などを持ち上げることもある。10センチほどある爪で、数百キログラムもある獲物も一撃で捉えることができる。";
		}
		if (level == 5) {
			animal = "Bull";
			columnText = "競技の種類にもよるが、体重はおよそ500〜800キログラム。他の牛とは比べ、肩や首回りの筋肉が発達しており、ツノが長いのが特徴。スペインでは闘牛士と戦う競技は国技として認められているが、牛は記憶力が高いため、危険性を考慮して競技の実践は一度のみになっている。";
		}
		if (level == 6) {
			animal = "Gorilla";
			columnText = "平均体重は130〜230キログラム。体の筋肉率は人間のおよそ4倍、骨は3倍も厚く重い。体重のおよそ10倍を持ち上げる力があると予測されており、１匹の力はヒト20人を合わせたものも上回る。草食動物でありながらも鋭い牙と強い顎を備えており、噛んだ強さライオンの倍はあると言われている。";
		}
		if (level == 7) {
			animal = "Boar";
			columnText = "日本国内のイノシシは成獣して70kgほどあるが、海外には300キログラム以上のものも記録されている。 鼻の先で押す力が特に強く、雄だと70kg、雌だと60kgほどの石などを動かすことができる。これを利用して倒木などの障害物により直接口を付けられない餌も採餌できる。";
		}
		if (level == 8) {
			animal = "Dog/Wolf";
			columnText = "大型犬で最も巨大なのが、マスティフ種。中でも最大のイングリッシュ・マスティフの雄は体高80センチ近くあり、体重は110キログラムにもおよぶ。中国のチベットで生まれた犬種は巨大で筋肉質のため、チベットではヒョウやオオカミから家畜を守る番犬として活躍してきた。";
		}
		if (level == 9) {
			animal = "Sheep";
			columnText = "品種によってまったく角をもたないもの、雄雌両方にあるもの、雄だけが角を持つものがある。また、体長や体重は品種により大きく異なり、雌の体重はおよそ45〜100kg、雄はより大きくて45〜160kgである。危険に対する防御行動は単純に危険から逃げ出すことであるが、時には突撃したり、蹄を踏み鳴らして威嚇する。";
		}
	} else if (contentId === 'c10203') {
		contTitle = 'SPEED SIDE STEPS'
		if (level == 1) {
			animal = "Hawk";
			columnText = "水平飛行では時速30〜60kmで移動する鷹だが、獲物を捕獲するために急降下する時には時速190kmを記録している。両方の翼を折りたたんで、尾っぽの部分の羽も閉じた状態で空気抵抗を軽減し、獲物を目掛けてダイブする。";
		}
		if (level == 2) {
			animal = "Horse";
			columnText = "競技で有名なサラブレッドよりも速いとされている品種がアメリカンクォーターホース。 ややがっしりとした筋肉質の体型を持ち、優れた短距離の瞬発力を備えている。主として乗馬・牧畜作業・競馬用として使用。世界各地で400万頭余りが登録されており、事実上世界で最も頭数の多い品種である";
		}
		if (level == 3) {
			animal = "Puma";
			columnText = "時速80kmのスピードで走ることができ、長さでいうと10メートルは飛べる。しかし長距離には向いていないので、獲物は走って追うよりは、背後に忍び寄って飛びつくことで捕獲している。";
		}
		if (level == 4) {
			animal = "Kangaroo";
			columnText = "短距離だと最高時速68kmものスピードで移動ができる。強靭な後肢でジャンプして進み、一歩で7メートルをも飛べる。";
		}
		if (level == 5) {
			animal = "Orca";
			columnText = "最高速度が時速65kmのシャチのスピードは、海洋哺乳類の中でもトップクラス。イルカ属では最大と言われ、体長は7〜9メートルほどで重さ6トンにもおよぶ。長距離を泳ぐ習性があり、2000キロも移動したものも記録されている。";
		}
		if (level == 6) {
			animal = "Wolf";
			columnText = "ドッグレースで使われるグレイハウンドは最高速度は時速75km。もとはウサギなどの小動物を狩るために作り出された狩猟犬種で、俊足でスタイルがよく美しいため、ヨーロッパでは貴族のみが飼育・所持を許された特別な犬種だった。";
		}
		if (level == 7) {
			animal = "Boar";
			columnText = "日本国内のイノシシは成獣して70kgほどあるが、海外には300キログラム以上のものも記録されている。 時速45kmで走ることができ、成人男性を跳ね飛ばすこともある。特にオスにはとても鋭い牙があり、襲われると非常に危険である。";
		}
		if (level == 8) {
			animal = "Hippopotamus";
			columnText = "数トンの体でも加速が速く、地上では最高時速48キロで走れる。しかしスタミナに不足していて、時速30kmのスピードを保てるのはわずか数十秒。";
		}
		if (level == 9) {
			animal = "Elephant";
			columnText = "アフリカゾウは時速約40キロの速度で走ることができ、オリンピック短距離走選手の平均スピードである時速35.4キロよりも速い。また、アフリカゾウよりもやや小柄なアジアゾウは、時速25キロほどで走るとされる。";
		}
	}


	// getModalStyle is not a pure function, we roll the style only on the first render
	const [modalStyle] = React.useState(getModalStyle);
	const [open, setOpen] = React.useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	const body = (
		<div className={cn.modalVideo}>
			<video controls className={cn.movie} poster={movImage} src={movUrl} ></video>
			{/* <ReactPlayer url={movUrl} /> */}
			{/* {movUrl} */}
		</div>

	);

	return (
		<div className={cn.modalContent}>

			<div className="tex-c">
				<div className={cn.topTitle}>
					HIGH-TECH PHYSICAL TEST <br />
					“ANIMALIZE”
				</div>
				<div className={cn.contTitle}>
					{contTitle}
				</div>
			</div>
			<div className="center">
				<div item className={cn.dateTitle}>
					{/* <Moment format="YYYY/MM/DD">{Date(playDate)}</Moment> */}
				</div>
				{/* <div item className={cn.placeText}>MIYASHITA PARK</div> */}
			</div>

			<div className={cn.image + " " + ""}>
				<img className={cn.pic} src={picUrl} />
			</div>

			<div className={cn.scoreWrap + " " + "flex"}>
				<div className={cn.left + " " + ""}>
					<div className={cn.resultText}>YOUR SCORE</div>
					<div className={cn.scoreText}>{score}</div>
					<div className={cn.unit}>{unit}</div>
				</div>
				<div className={cn.right + " " + ""}>
					<div className={cn.resultText}>ANIMAL LEVEL</div>
					<div className={cn.scoreText}>{animal}</div>
				</div>
			</div>

			<div className={cn.columnTitle}>NAKED SPOREV. Column</div>
			<div className={cn.columnText}>{columnText}</div>

			<div className={cn.buttonWrap}>
				<div className={cn.button}>
					{/* <PrimaryButton
                        size="small"
                        label={"画像で保存する"}
                        onClick={() => downloadImg(picUrl)}
                    /> */}
					{/* <p onClick={() => downloadImg(picUrl)}>画像で保存する</p> */}
				</div>
				<div className={cn.buttonWrap + " " + "tex-c"}>
					<PrimaryButton
						label={"動画を見る"}
						// size="small"
						onClick={handleOpen}
					/>
				</div>
			</div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				className="video"
			>
				{body}
			</Modal>
		</div>

	)
}
export default ResultDetailCard