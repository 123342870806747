import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import Motion from "../components/UIkit/motion";
import cn from "../assets/sass/start.module.scss"

const EditedPersonalData = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    window.setTimeout(() => dispatch(push("/home")), 5000);

    return () => { };
  }, []);

  const selector = useSelector((state) => state);
  const provider = selector.users.providerName;
  const email = selector.users.email;

  return (
    <Motion>
      <div className={cn.signin + " " + "t-wh tex-c"}>
        <div className={cn.inner + " " + "tra-c wid-100 fon-s80"}>

          <div className="c-section-container">
            <div className="module-spacer--medium" />
            <div className={cn.JPtext}>登録完了しました</div>
            <div className="module-spacer--medium" />
            {provider === "email" ? (
              <div className={cn.JPtext}>
                登録に使用したメールアドレスは
                <br />
                {email}です
              </div>
            ) : (
              <div className={cn.JPtext}>
                登録に使用したSNSアカウントは
                <br />
                {provider}です
              </div>
            )}

            <div className="module-spacer--medium" />
            <div className="module-spacer--medium" />
            <div className={cn.JPtext}>
              ※必ず同じアカウントでサインインしてください。アカウントが異なる場合鑑定結果が受け取れない場合がございます。サインインしたアカウントの情報を控えておいてください。{" "}
            </div>
            <div className="module-spacer--medium" />
            <div className="module-spacer--medium" />
            <div className={cn.JPtext}>5秒でホーム画面へ遷移します</div>
          </div>
        </div>
      </div>
    </Motion>
  );
};

export default EditedPersonalData;
