import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'
import {firebaseConfig} from "./config";

// require('firebase/auth')

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
// export const providerGoogle = firebase.auth.GoogleAuthProvider();
export const providerFacebook = firebase.auth.FacebookAuthProvider();
// export const providerTwitter = firebase.auth.TwitterAuthProvider();
export const db = firebase.firestore();
export const strage = firebase.storage();
export const functions = firebase.functions();
export const FirebaseTimestamp = firebase.firestore.Timestamp;

