import React, { useEffect } from "react";
import { getUserId } from "../reducks/users/selectors";
import { getIntId, getContentId } from "../reducks/interactives/selectors";
import Motion from "../components/UIkit/motion";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import QRCode from "qrcode.react";
import { db } from "../firebase/index";
import cn from "../assets/sass/home.module.scss"

const Qr = () => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const qrid = getIntId(selector);
  const uid = getUserId(selector);
  const contentId = getContentId(selector);

  let contentName = "";

  if (contentId === "c10201") {
    contentName = "VERTICAL POWER JUMP";
  } else if (contentId === "c10202") {
    contentName = "FULL BODY PRESS";
  } else if (contentId === "c10203") {
    contentName = "SPEED SIDE STEPS";
  }

  useEffect(() => {
    var unsubscribe = db
      .collection("User")
      .doc(uid)
      .collection("Interactive")
      .doc(qrid)
      .onSnapshot((doc) => {
        const data = doc.data();
        if (doc.data() !== undefined) {
          if (data.isStarted) {
            dispatch(push("/home"));
          }
        }
      });
    return () => {
      unsubscribe();
    };
  }, []);

  return (

    <Motion>
      <div className={cn.qrWrap}>
        <div className="mid_wrap tex-c t-wh fon-s80">
          <div className="module-spacer--medium" />
          <div className="module-spacer--medium" />
          <div className={"mid_wrap"}>
            {contentName}
          </div>
          <div className="module-spacer--medium" />
          <div className={cn.JPtextS}>
            この専用コードを
            <br />「{contentName}」エリアの
            <br />
            QRコードリーダーにかざしてください。
          </div>
          <div className="module-spacer--medium" />
          <div className={cn.qr + " " + "pad-10"}>
            <QRCode value={qrid} />
          </div>
          <div className="module-spacer--medium" />
          <div className={cn.JPtextSS}>
            ※読み込みエラーになった場合は前の画面に戻り、
            <br />
            再度「専用コードを表示する」ボタンを押してください。
          </div>
          <div className="module-spacer--medium" />

          <div
            className={cn.JPtextS}
            onClick={() => dispatch(push("/home"))}
          >
            閉じる
          </div>
        </div>
      </div>
    </Motion>

  );
};
export default Qr;
