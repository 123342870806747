import React, { useState, useEffect, useCallback, memo } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";

const FormControlLabels = styled(FormControlLabel)`
  root: {
    color: var(--main);
  }
  .MuiCheckbox-root {
    &.Mui-checked {
      color: var(--main);
    }
  }
  .MuiTypography-body1{
    font-size:.7em;
  }
`;

const CheckboxInput = memo(({ id, value, setValue }) => {
  const handleChange = useCallback(
    (event) => {
      if (value) {
        setValue("");
      } else {
        setValue(event.target.value);
      }
    },
    [value]
  );

  return (
    <FormGroup>
      <FormControlLabels
        control={
          <Checkbox value={id} onChange={handleChange} />
        }
        label={id}
      />
    </FormGroup>
  );
});
export default CheckboxInput;
