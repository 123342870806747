import * as Actions from './actions'
import initialState from '../store/initialState'

export const InteractivesReducer = (state = initialState.interactives, action) => {
    switch(action.type) {
        case Actions.FETCH_INTERACTIVES:
            return {
                ...state,
                list: [...action.payload]
            }
        case Actions.CREATE_INTERACTIVES:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}