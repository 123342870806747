import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { deleteAccount } from '../reducks/users/operations';
import { getUserId } from '../reducks/users/selectors';
import cn from "../assets/sass/start.module.scss"


const ConfirmDelete = () => {
    const dispatch = useDispatch();

    const selector = useSelector((state => state));
    const uid = getUserId(selector);

    return (
        <div className={cn.signin + " " + "t-wh tex-c"}>
            <div className={cn.inner + " " + "tra-c wid-100"}>
                <div className="module-spacer--medium" />
                <div className="c-section-container">
                    <div className="module-spacer--medium" />
                    <div className={cn.JPtext}>アカウントを削除しますか？</div>
                    <div className="module-spacer--medium" />

                    <div className="module-spacer--medium" />
                    <div className="module-spacer--medium" />
                    <div className={cn.JPtext}>※この操作は取り消すことができません。 </div>
                    <div className="module-spacer--medium" />
                    <div className="module-spacer--medium" />
                    <div className={cn.JPtextS} onClick={() => dispatch(push('/home'))}>ホームへ戻る</div>
                    <div className="module-spacer--medium" />
                    <div className="module-spacer--medium" />
                    <div className={cn.JPtextS} onClick={() => dispatch(deleteAccount(uid))}>アカウントを削除する</div>
                </div>
            </div>
        </div>

    )
}

export default ConfirmDelete