import React, { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listenAuthState } from './reducks/users/operations';
import { getIsSignedIn } from './reducks/users/selectors';

const Auth = ({ children }) => {
    const dispatch = useDispatch();
    const selector = useSelector((state => state));
    const isSignedIn = getIsSignedIn(selector);
    console.log("state isSignedIn:" + isSignedIn)
    useLayoutEffect(() => {
        if (!isSignedIn) {
            dispatch(listenAuthState())
        }
    }, []);

    return children
}
export default Auth;