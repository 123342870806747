import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PrimaryButton } from '../components/UIkit';
import { push } from 'connected-react-router';
import logo from "../assets/img/src/logo_sporev.svg";
import cn from "../assets/sass/start.module.scss";


const Start = () => {
	const dispatch = useDispatch();
	const selector = useSelector(state => state);

	return (
		<div className="root start pos-r bgimg">
			<div className="tra-c wid-100 tex-c pad-20">

				<div className={cn.headText + " " + "t-wh mar-b1"}>
					{/* <h1 className="fon-4 fon-b">NAKED URANAI</h1> */}
					<div className={cn.logo + " " + ""}>
						<img src={logo} alt="" />
					</div>
				</div>
				<div className={cn.text}>
					自分の身体能力と動きがアートになる、<br />
					新体力測定、アニマライズ！
				</div>


				<div className={cn.startButton}>
					<PrimaryButton
						label={"サインインして体験する"}
						onClick={() => dispatch(push("/signin"))}
					/>
				</div>

			</div>
			{/* <img className={cn.logoFooter} src={logoFooter} /> */}
		</div >

	)
}

export default Start