import React, { useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Motion from "../components/UIkit/motion";
import cn from "../assets/sass/home.module.scss"
import {
  ContentsCard,
  HeaderSNS,
} from "../components/UIkit";
import { push } from "connected-react-router";
import { getDisplayName, getUserId, getEmail, getGender, getPrefecture, getQuestionnaire } from "../reducks/users/selectors";
import { PrimaryButton } from "../components/UIkit";
import { db } from "../firebase/index";
import logo from "../assets/img/src/logo_sporev.svg";
import PersonIcon from '@mui/icons-material/Person';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import HoutoPlay from "../components/UIkit/howtoPlay"

const drawerBleeding = 10;

const Home = () => {
  const dispatch = useDispatch();

  const selector = useSelector((state) => state);
  const dispName = getDisplayName(selector);
  const isQuestionnaire = getQuestionnaire(selector);
  const email = getEmail(selector);
  const gender = getGender(selector);
  const uid = getUserId(selector);
  const prefecture = getPrefecture(selector);

  if (dispName === "" || dispName === undefined || email === "" || email === undefined || gender === "" || gender === undefined || prefecture === "" || prefecture === undefined) {
    dispatch(push("/mypage/edit"));
  }

  const [isDisable, setIsDisable] = useState(true);
  // const [isDisableFbp, setIsDisableFbp] = useState(true);
  // const [isDisableSss, setIsDisableSss] = useState(true);


  const getFlags = async (uid) => {
    await db
      .collection("User")
      .doc(uid)
      .collection("Interactive")
      .get()
      .then((snapshots) => {
        snapshots.forEach((snapshot) => {
          const data = snapshot.data();
          // console.log(data)
          if (data.contentId === "c10201" && data.isPlayed === true) {
            setIsDisable(false);
          } else if (data.contentId === "c10202" && data.isPlayed === true) {
            setIsDisable(false);
          } else if (data.contentId === "c10203" && data.isPlayed === true) {
            setIsDisable(false);
          }
        });
      });
  };

  useLayoutEffect(() => {
    getFlags(uid);
  }, []);


  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  // const result =  dispatch(isAnsweredQuestionnaire(uid))


  return (

    <Motion>
      <div className={cn.root}>
        <HeaderSNS />
        <button
          className={cn.myButton + " " + "myPageButton"}
          onClick={() => dispatch(push("/mypage/edit"))}
        >
          <PersonIcon fontSize="small" />
          マイページ
        </button>

        <div className="mid_wrap">
          <div className={cn.logo}>
            <img src={logo} alt="" />
          </div>

          <div className={cn.contentsInfoWrap + " " + ""}>
            <div className={cn.inner + " " + "t-wh tex-c"}>

              <div className={cn.title}>
                HIGH-TECH PHYSICAL TEST<br />
                “ANIMALIZE”
              </div>

              <div className={cn.text + " " + ""}>
                自分の身体能力と動きがアートになる、<br />
                新体力測定、アニマライズ！<br />
                「垂直跳び」「反復横跳び」<br />
                体験したい作品を選び自分だけの<br />
                マイスコアとアートを手に入れよう。
              </div>

              <PrimaryButton
                label={"遊び方を見る"}
                onClick={toggleDrawer(true)}
              />
              <div className="module-spacer--small" />
              <PrimaryButton
                label={"結果を見る"}
                onClick={() => dispatch(push("/result"))}
                disabled={isDisable}
              />
            </div>
          </div>

          <SwipeableDrawer
            anchor="bottom"
            id="drower"
            className={cn.drawerWrap}
            open={open}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            swipeAreaWidth={drawerBleeding}
            disableSwipeToOpen={false}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <HoutoPlay open={open} onClose={toggleDrawer(false)} />
          </SwipeableDrawer>

          {/************* タロットカード　スリーオラクル *************/}
          <ContentsCard
            mainText="VERTICAL POWER JUMP"
            qrText="Vpj"
            resultURL="/result"
            titleText=""
            // isDisabled={isDisableVpj}
            uid={uid}
          />
          <div className="module-spacer--medium" />

          {/************* 「HOROSCOPE」／占法：西洋占星術 *************/}

          <ContentsCard
            mainText="SPEED SIDE STEPS"
            qrText="Sss"
            resultURL="/result"
            titleText=""
            // isDisabled={isDisableSss}
            uid={uid}
          />

        </div>



        {/* <FooterNav /> */}
      </div>
    </Motion>

  );
};

export default Home;
