export const SIGN_IN = "SIGN_IN";
export const signInAction = (userState) => {
    return {
        type: "SIGN_IN",
        payload: {
            isSignedIn: true,
            uid: userState.uid,
            displayName: userState.displayName,
            birth: userState.birth,
            gender: userState.gender,
            prefecture: userState.prefecture,
            purchaseId: userState.purchaseId,
            email: userState.email,
            providerName: userState.providerName,
            tall: userState.tall,
            weight: userState.weight,
            distortion: userState.distortion,
            skeleton: userState.skeleton,
            studentNum: userState.studentNum,
            isQuestionnaire: userState.isQuestionnaire
        }
    }
};

export const UPDATE = "UPDATE";
export const updateAction = (userState) => {
    return {
        type: "UPDATE",
        payload: {
            displayName: userState.displayName,
            birth: userState.birth,
            gender: userState.gender,
            prefecture: userState.prefecture,
            purchaseId: userState.purchaseId,
            email: userState.email,
            tall: userState.tall,
            weight: userState.weight,
            distortion: userState.distortion,
            skeleton: userState.skeleton,
            studentNum: userState.studentNum,
            isQuestionnaire: userState.isQuestionnaire
        }
    }
};

export const SIGN_OUT = "SIGN_OUT";
export const signOutAction = (userState) => {
    return {
        type: "SIGN_OUT",
        payload: {
            isSignedIn: false,
            uid: "",
            qrcode: "",
            displayName: "",
            gender: "",
            birth: "",
            birthPlace: "",
            email: "",
            providerName: "",
            tall: "",
            weight: "",
            distortion: "",
            skeleton: "",
            studentNum: "",
            isQuestionnaire: ""
        }
    }
};