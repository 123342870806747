import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PrimaryButton } from '../components/UIkit';
import { resetPassword } from '../reducks/users/operations';
import { push } from 'connected-react-router';
import TextInput from "../components/form/TextInput";

import cn from "../assets/sass/start.module.scss";
import form from "../json/formData.json";


const Reset = () => {

	const dispatch = useDispatch()
	const [email, setEmail] = useState("");

	const isDisable =
		email !== "";

	return (
		<div className={cn.signin + " " + "t-wh tex-c"}>
			<div className={cn.inner + " " + "tra-c wid-100"}>

				<h2 className={cn.title}>パスワードのリセット</h2>

				<div className={cn.text}>登録したメールアドレスを入力してください。</div>
				<TextInput id={form.fields[0]} setValue={setEmail} />

				<div className="center mar-t2">
					<PrimaryButton
						label={"パスワードをリセットする"}
						onClick={() => dispatch(resetPassword(email))}
						disabled={!isDisable}
					/>
				</div>

				<p className={cn.mailLogin} onClick={() => dispatch(push('/signin'))}>ログイン画面に戻る</p>
			</div>
		</div>
	)
}

export default Reset