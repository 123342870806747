import { fetchInteractivesAction, createInteractivesAction } from "./actions"
import { push } from 'connected-react-router';
import { db, FirebaseTimestamp } from '../../firebase/index'
// import firebase from 'firebase/app'
import "firebase/auth";


const InteractiveRef = db.collection('Interactive')
const UserRef = db.collection('User')

export const openQrPage = (contents, uid) => {
    return async (dispatch) => {
        let intId = ""
        const timestamp = FirebaseTimestamp.now()
        switch (contents) {

            case "Vpj":
                // let CollectionCreateFlag = false
                // let intId = ""
                await UserRef.doc(uid).collection('Interactive').get()
                    .then(snapshots => {
                        const interactiveList = []
                        snapshots.forEach(snapshot => {
                            const interactive = snapshot.data()
                            // console.log("test1")
                            // console.log(snapshot.id)
                            if (interactive.contentId === "c10201") {
                                interactiveList.push(interactive)
                                if (!interactive.isPlayed) {
                                    // CollectionCreateFlag = true
                                    const deleteIntId = snapshot.id
                                    UserRef.doc(uid).collection('Interactive').doc(deleteIntId).delete()
                                        .then(() => {
                                            // console.log("Document successfully deleted!");
                                        })
                                    InteractiveRef.doc(deleteIntId).delete()
                                        .then(() => {
                                            // console.log("Document successfully deleted!");
                                        })

                                }

                            }

                        })
                    })
                const UserInteractiveRefVpj = UserRef.doc(uid).collection("Interactive")
                const IntrefVpj = UserInteractiveRefVpj.doc()
                intId = IntrefVpj.id
                const UserInteractiveVpjInitialData = {
                    contentId: "c10201",
                    createTime: timestamp,
                    lastTime: timestamp,
                    p01: "",
                    p02: "",
                    d01: "",
                    d02: "",
                    isStarted: false,
                    isPlayed: false
                }
                const InteractiveVpjInitialData = {
                    uId: uid,
                    createTime: timestamp,
                    lastTime: timestamp,
                    expireTime: "",
                    contentId: "c10201"
                }
                UserRef.doc(uid).collection("Interactive").doc(intId).set(UserInteractiveVpjInitialData)
                InteractiveRef.doc(intId).set(InteractiveVpjInitialData).then(() => {
                    dispatch(push("/qr"))
                });
                dispatch(createInteractivesAction({
                    uid: uid,
                    intId: intId,
                    contentId: "c10201"
                }))
                break;
            case "Sss":
                // let CollectionCreateFlag = false
                // let intId = ""
                await UserRef.doc(uid).collection('Interactive').get()
                    .then(snapshots => {
                        const interactiveList = []
                        snapshots.forEach(snapshot => {
                            const interactive = snapshot.data()
                            // console.log("test1")
                            // console.log(snapshot.id)
                            if (interactive.contentId === "c10203") {
                                interactiveList.push(interactive)
                                if (!interactive.isPlayed) {
                                    // CollectionCreateFlag = true
                                    const deleteIntId = snapshot.id
                                    UserRef.doc(uid).collection('Interactive').doc(deleteIntId).delete()
                                        .then(() => {
                                            // console.log("Document successfully deleted!");
                                        })
                                    InteractiveRef.doc(deleteIntId).delete()
                                        .then(() => {
                                            // console.log("Document successfully deleted!");
                                        })

                                }

                            }

                        })
                    })
                const UserInteractiveRefSss = UserRef.doc(uid).collection("Interactive")
                const IntrefSss = UserInteractiveRefSss.doc()
                intId = IntrefSss.id
                const UserInteractiveSssInitialData = {
                    contentId: "c10203",
                    createTime: timestamp,
                    lastTime: timestamp,
                    p01: "",
                    p02: "",
                    d01: "",
                    d02: "",
                    isStarted: false,
                    isPlayed: false
                }
                const InteractiveSssInitialData = {
                    uId: uid,
                    createTime: timestamp,
                    lastTime: timestamp,
                    expireTime: "",
                    contentId: "c10203"
                }
                UserRef.doc(uid).collection("Interactive").doc(intId).set(UserInteractiveSssInitialData)
                InteractiveRef.doc(intId).set(InteractiveSssInitialData).then(() => {
                    dispatch(push("/qr"))
                });
                dispatch(createInteractivesAction({
                    uid: uid,
                    intId: intId,
                    contentId: "c10201"
                }))
                break;
            default:
                console.log("なし")
        }

    }
}



export const fetchInteractives = (uid) => {
    return async (dispatch) => {
        UserRef.doc(uid).collection('Interactive').orderBy('lastTime', 'asc').get()
            .then(snapshots => {
                const interactiveList = []
                snapshots.forEach(snapshot => {
                    const interactive = snapshot.data()
                    interactiveList.push(interactive)
                })
                dispatch(fetchInteractivesAction(interactiveList))

            })
    }
}

export const isAnsweredQuestionnaire = (uid) => {
    return async (dispatch) => {
        UserRef.doc(uid).get()
            .then(snapshot => {
                const userData = snapshot.data()
                if (!userData.isAnsweredQuestionnaire) {
                    dispatch(push("/form"))
                } else {
                    return false
                }
            })
    }
}

export const onAnsweredQuestionnaire = (uid) => {
    return async (dispatch) => {
        UserRef.doc(uid).set({
            isAnsweredQuestionnaire: true
        }, { merge: true }).then(() => {
            // console.log(uid)
            dispatch(push("/mypage"))
        })
    }
}

export const setToDatabase = (uid) => {
    return async (dispatch) => {

        UserRef.doc(uid).set({
            isAnsweredQuestionnaire: true
        }, { merge: true }).then(() => {
            // console.log(data)
            // UserRef.doc(uid).collection("Questionnaire").doc().set(data)
            dispatch(push("/result"))
        })
    }

}