import {
    createStore as reduxCreateStore,
    combineReducers,
    applyMiddleware,
    compose
} from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import persistState from "redux-localstorage";

//Import reducers
import { UsersReducer } from '../users/reducers';
import { InteractivesReducer } from '../interactives/reducers';

export default function createStore(history) {
    return compose(persistState())(reduxCreateStore)(
        // return reduxCreateStore(
        combineReducers({
            router: connectRouter(history),
            interactives: InteractivesReducer,
            users: UsersReducer
        }),
        applyMiddleware(
            routerMiddleware(history),
            thunk
        )
    )
}