const initialState = {
    interactives:{
        list: []
    },
    users: {
        isSignedIn: false,
        uid: "",
        qrcode: "",
        displayName:"",
        gender:"",
        tall:"",
        weight:"",
        birth:"",
        prefecture:"",
        purchaseId:"",
        email:"",
        providerName:""
    }
};

export default initialState