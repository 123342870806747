import React from "react";
import Router from "./Router";
import "./assets/base.scss";
import "./assets/style.scss";
import { AnimateSharedLayout } from "framer-motion";
import ScrollToTop from "./components/UIkit/ScrollToTopOnMount";
import Footer from "./components/UIkit/Footer"

const App = () => {
  return (
    <AnimateSharedLayout>

      <ScrollToTop />
      <Router />
      <Footer />
    </AnimateSharedLayout>
  );
};

export default App;
