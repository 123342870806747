import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PrimaryButton } from '../components/UIkit';
import { signUp } from '../reducks/users/operations';
import { push } from 'connected-react-router';
import cn from "../assets/sass/start.module.scss";
import CheckboxInput from "../components/form/CheckboxInput";
import TextInput from "../components/form/TextInput";
import form from "../json/formData.json";

const EmailSignUp = () => {
	const dispatch = useDispatch()
	const [email, setEmail] = useState(""),
		[password, setPassword] = useState(""),
		[confirmPassword, setConfirmPassword] = useState(""),
		[stateCheck, setStateCheck] = useState("");

	const isDisable =
		email !== "" &&
		password !== "" &&
		confirmPassword !== ""

	return (
		<div className={cn.signin + " " + "t-wh tex-c"}>
			<div className={cn.inner + " " + "tra-c wid-100"}>

				<div className={cn.title}>アカウントを作成しましょう</div>

				<div className="mar-b1">
					<TextInput id={form.fields[0]} setValue={setEmail} />
				</div>
				<div className="mar-b1">
					<TextInput id={form.fields[1]} setValue={setPassword} />
				</div>
				<TextInput id={form.fields[2]} setValue={setConfirmPassword} />
				{/* <TextInput
					fullWidth={true} label={"パスワード再確認"} multiline={false} required={true}
					rows={1} value={confirmPassword} type={"password"} onChange={inputConfirmPassword}
				/> */}


				<div className="center mar-t2">
					{/* 
					<a
						className={cn.linkSub}
						href="https://naked.co.jp/termsofservice/"
						target="_blank"
					>
						利用規約を見る
					</a>

					<a
						className={cn.linkSub}
						href="https://naked.co.jp/privacypolicy/"
						target="_blank"
					>
						プライバシーポリシーを見る
					</a>

					<div className={"tex-c mar-b05"}>
						<CheckboxInput
							id={"規約に同意する"}
							setValue={setStateCheck}
							value={stateCheck}
						/>
					</div> */}
					<div className="mar-t05">
						<PrimaryButton
							label={"アカウントを登録する"}
							onClick={() => dispatch(signUp(email, password, confirmPassword, stateCheck))}
							disabled={!isDisable}
						/>
					</div>

					<div className={cn.mailLogin} onClick={() => dispatch(push('/signin'))}>SNSアカウントでサインインする</div>
				</div>
			</div>
		</div>
	)
}

export default EmailSignUp