import React, { useCallback, useState } from "react";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import cn from "../assets/sass/start.module.scss";
import {
  signIn,
  signInWithProviderFacebook,
  signInWithProviderTwitter,
  signInWithProviderGoogle,
} from "../reducks/users/operations";
import Motion from "../components/UIkit/motion";
import Button from '@mui/material/Button';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import Modal from "../components/UIkit/utilityModal"

const SignIn = () => {
  const dispatch = useDispatch();

  return (
    <>
      <Motion>

        <Modal id={"firstModal"} click={false} defaultShow={true} closeLabel="規約・第三者提供に同意して続ける" >
          <div className={cn.modalInner + " " + "tex-c"}>
            <p>このコンテンツをご利用いただく際は、利用規約への同意が必要です。
              以下の利用規約とプライバシーポリシーをご確認ください。
            </p>
            <div className="module-spacer--medium" />
            <a
              className={cn.linkSub}
              href="https://naked.co.jp/termsofservice/"
              target="_blank"
            >
              利用規約を見る
            </a>
            <div className="module-spacer--extra-small" />
            <a
              className={cn.linkSub}
              href="https://naked.co.jp/privacypolicy/"
              target="_blank"
            >
              プライバシーポリシーを見る
            </a>
            <div className="module-spacer--extra-small" />
          </div>
        </Modal>
        <div className={cn.signin + " " + "t-wh tex-c"}>
          <div className={cn.inner + " " + "tra-c wid-100"}>
            <div className={cn.title}>
              アカウントを作成しましょう
            </div>
            <div className={cn.text}>
              お持ちのアカウントでサインインする
            </div>

            <Button className={`${cn.snsButton} ${cn.tw}`} variant="contained" startIcon={<TwitterIcon />} onClick={() => dispatch(signInWithProviderTwitter())}>
              Sign In with Twitter
            </Button>

            <Button className={`${cn.snsButton} ${cn.fb}`} variant="contained" startIcon={<FacebookIcon />} onClick={() => dispatch(signInWithProviderFacebook())}>
              Sign In with Facebook
            </Button>

            <Button className={`${cn.snsButton} ${cn.google}`} variant="contained" startIcon={<GoogleIcon />} onClick={() => dispatch(signInWithProviderGoogle())}>
              Sign In with Google
            </Button>

            <div className="module-spacer--extra-small" />

            <div className="module-spacer--medium" />
            {/* <div className={cn.infoText}>
              サインインされた時点で
              <br />
              以下の規約に同意したとみなされます
            </div>

            <a
              className={cn.link}
              href="https://naked.co.jp/termsofservice/"
              target="_blank"
            >
              利用規約を見る
            </a>

            <a
              className={cn.link}
              href="https://naked.co.jp/privacypolicy/"
              target="_blank"
            >
              プライバシーポリシーを見る
            </a> */}

            <div
              className={cn.mailLogin}
              onClick={() => dispatch(push("/email-login"))}
            >
              または、メールアドレスでサインインする
            </div>
          </div>
        </div>
      </Motion>
    </>
  );
};

export default SignIn;
