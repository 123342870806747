import React from "react";
import { useSelector } from "react-redux";
import cn from "../css/headerSns.module.scss"
const HeaderSNS = () => {
  const selector = useSelector((state) => state);
  const provider = selector.users.providerName;
  const email = selector.users.email;


  let SNS = cn.Twitter;
  if (provider === "Facebook") {
    SNS = cn.Facebook;
  } else if (provider === "google") {
    SNS = cn.Google;
  } else if (provider === "email") {
    SNS = cn.Email;
  }
  return (
    <div className={cn.headerSns}>
      <div className={SNS}>
        {provider === "email" ? (
          <div className={cn.JPtext}>
            現在メールアドレス{email}でサインイン中です
          </div>
        ) : (
          <div className={cn.JPtext}>
            現在{provider}アカウントでサインイン中です
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderSNS;
