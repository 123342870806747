import React, { useEffect, useState } from "react";
import cn from "../css/howtoPlay.module.scss"

import number1 from "../../assets/img/src/1.svg";
import number2 from "../../assets/img/src/2.svg";
import number3 from "../../assets/img/src/3.svg";
import number4 from "../../assets/img/src/4.svg";
import number5 from "../../assets/img/src/5.svg";
import imscan from "../../assets/img/src/scan.png";
import implay from "../../assets/img/src/play.png";
import imart from "../../assets/img/src/art.png";
import imscore from "../../assets/img/src/score.png";
import imshare from "../../assets/img/src/share.png";

const HowtoPlay = ({ onClose, open }) => {

  useEffect(() => {
    const modal = document.getElementsByClassName("MuiPaper-root")[0];
    modal.scrollTo(0, 0);
  }, [onClose]);

  return (
    <div id="howtoPlay" className={cn.howtoPlay}>
      <div className={cn.inner + " " + ""}>
        <h1 className={cn.mainTitle}>HOW TO PLAY</h1>
        <p className="fon-2 lin-3">HIGH-TECH PHYSICAL TEST<br />
          “ANIMALIZE”の遊び方</p>

        <div className={cn.numImage + " " + ""}>
          <img src={number1} alt="" />
        </div>

        <div className={cn.DialgJPtitle}>体験前にBODY SCANをします</div>

        <div className={cn.playImage + " " + ""}>
          <img className={cn.imBack} src={imscan} />
        </div>

        <div className={cn.numImage + " " + ""}>
          <img src={number2} alt="" />
        </div>

        <div className={cn.DialgJPtitle}>実際にコンテンツ体験で身体を動かす</div>

        <div className={cn.playImage + " " + ""}>
          <img className={cn.imBack} src={implay} />
        </div>

        <div className={cn.numImage + " " + ""}>
          <img src={number3} alt="" />
        </div>

        <div className={cn.DialgJPtitle}>スポーツで自分のアートを創り出そう</div>

        <div className={cn.playImage + " " + ""}>
          <img className={cn.imBack} src={imart} />
        </div>

        <div className={cn.numImage + " " + ""}>
          <img src={number4} alt="" />
        </div>

        <div className={cn.DialgJPtitle}>SPOREV. SCOREで自己分析<br />コンテンツ体験終了後にこのサイト再度にアクセスし体験結果をみましょう。</div>

        <div className={cn.playImage + " " + ""}>
          <img className={cn.imBack} src={imscore} />
        </div>

        <div className={cn.numImage + " " + ""}>
          <img src={number5} alt="" />
        </div>

        <div className={cn.DialgJPtitle}>
          SPOREV. ARTをSNSでシェア！自分で創り出したアニマライズ作品をダウンロード。ハッシュタグをつけてSNSに投稿し、他の人のアート作品も見てみよう。
        </div>

        <div className={cn.hashTag + " " + ""}>
          #ネイキッドスポレヴォ#nakedinc #ネイキッドインク
        </div>

        <div className={cn.playImage + " " + ""}>
          <img className={cn.imBack} src={imshare} />
        </div>

        <div className={cn.DialgJPtitle}>
          それでは、<br />
          HIGH-TECH PHYSICAL TEST<br />
          “ANIMALIZE”を体験しよう
        </div>

        <div className="module-spacer--medium" />

        <p className={cn.close} onClick={onClose}>閉じる</p>
      </div>
    </div>
  )
}
export default HowtoPlay;