import React, { useState, useEffect, memo, useCallback } from "react";
import cn from "./form.module.scss";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { styled } from "@mui/material/styles";

const FormControls = styled(FormControl)`
  .MuiFilledInput-root {
    background-color: rgb(255 255 255 / 0.7);
    :before {
      border-bottom: 1px solid #fff;
    }
    :after {
      border-bottom: 1px solid #fff;
    }
    :hover {
      background-color: rgb(255 255 255 / 0.7);
    }
    &.Mui-focused {
      background-color: rgb(255 255 255 / 0.7);
    }
  }
  .MuiRadio-root {
    color: #fff;
    &.Mui-checked {
      color: #fff;
    }
  }
  .MuiTypography-root {
    color: #fff;
  }
  .MuiFormLabel-root {
    color: #fff;
    font-size: 0.8em;
    &.Mui-focused {
      color: #fff;
    }
  }
`;

const RadioButton = memo(({ id, setValue, required, value }) => {

  let defaults = "";

  return (
    <FormControls>
      <FormLabel id={id.id} className={cn.labelColor}>
        {id.label}
      </FormLabel>
      <RadioGroup aria-labelledby={id.id} defaultValue={value} name={id.id} row>
        {id.options.map((value, key) => (
          <FormControlLabel
            key={key}
            value={value.value ? value.value : value.label}
            control={<Radio className={cn.radioColor} />}
            label={value.label}
            onChange={useCallback(
              (e) => setValue(e.target.value),
              [value.label]
            )}
          />
        ))}
      </RadioGroup>
    </FormControls>
  );
});
export default RadioButton;
