import React from 'react';
import logoNaked from "../../assets/img/src/logo_naked.svg"
import cn from "../css/footer.module.scss"

const Footer = (props) => {

    return (
        <div className="c-section-container" >
            <div className={cn.footer} >
                <img src={logoNaked} alt="" />
            </div>
        </div>


    )
}

export default Footer