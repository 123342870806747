import { signInAction, signOutAction, updateAction } from "./actions";
import { push } from "connected-react-router";

import { auth, db, FirebaseTimestamp } from "../../firebase/index";
import firebase from "firebase/app";
import "firebase/auth";
// import { isPresent } from "framer-motion/types/components/AnimatePresence/use-presence";
import { createInteractivesAction } from "../interactives/actions"

const InteractiveRef = db.collection("Interactive");
const UserRef = db.collection("User");

export const fetchInteractive = (uid) => {
  return async (dispatch) => {
    UserRef.doc(uid);
  };
};

export const listenAuthState = () => {
  return async (dispatch) => {
    return auth.onAuthStateChanged(user => {
      if (user) {
        const uid = user.uid;
        db.collection("User")
          .doc(uid)
          .get()
          .then((snapshot) => {
            const data = snapshot.data();
            const displayName = data.displayName;

            UserRef.doc(uid)
              .collection("Datas")
              .doc(uid)
              .get()
              .then((snapshotDatas) => {
                const dataDatas = snapshotDatas.data();
                const birth = dataDatas.birth.toDate();
                const gender = dataDatas.gender;
                const prefecture = dataDatas.prefecture;
                const email = dataDatas.email;
                const tall = dataDatas.tall;
                const weight = dataDatas.weight;
                const distortion = dataDatas.distortion;
                const skeleton = dataDatas.skeleton;
                const studentNum = dataDatas.studentNum;

                UserRef.doc(uid)
                  .collection("Provider")
                  .get()
                  .then((snapshotProviders) => {
                    snapshotProviders.forEach((snapshotProvider) => {
                      const dataProvider = snapshotProvider.data();
                      const providerName = dataProvider.providerName;

                      dispatch(
                        signInAction({
                          isSignedIn: true,
                          uid: uid,
                          displayName: displayName,
                          birth: birth,
                          gender: gender,
                          prefecture: prefecture,
                          email: email,
                          providerName: providerName,
                          tall: tall,
                          weight: weight,
                          distortion: distortion,
                          skeleton: skeleton,
                          studentNum: studentNum
                        })
                      );

                      dispatch(push("/home"));
                    });
                  });
              });
          });
      } else {
        dispatch(push("/signin"));
      }
    });
  };
};


const login = (uid, dispatch, providerName) => {
  // console.log("already created user")
  // /** @type {firebase.auth.OAuthCredential} */
  // const credential = result.credential;
  // const uid = result.user.uid

  db.collection("User")
    .doc(uid)
    .get()
    .then((snapshot) => {
      const data = snapshot.data();
      const displayName = data.displayName;
      const isQuestionnaire = data.isAnsweredQuestionnaire;
      UserRef.doc(uid)
        .collection("Datas")
        .doc(uid)
        .get()
        .then((snapshotPhis) => {
          const dataPhis = snapshotPhis.data();
          const birth = dataPhis.birth.toDate();
          const gender = dataPhis.gender;
          const prefecture = dataPhis.prefecture;
          const email = dataPhis.email;
          const tall = dataPhis.tall;
          const weight = dataPhis.weight;
          const distortion = dataPhis.distortion;
          const skeleton = dataPhis.skeleton;
          const studentNum = dataPhis.studentNum;
          dispatch(
            signInAction({
              isSignedIn: true,
              uid: uid,
              qrcode: "",
              displayName: displayName,
              birth: birth,
              gender: gender,
              prefecture: prefecture,
              purchaseId: "",
              email: email,
              providerName: providerName,
              tall: tall,
              weight: weight,
              distortion: distortion,
              skeleton: skeleton,
              studentNum: studentNum,
              isQuestionnaire: isQuestionnaire
            })
          );
          dispatch(push("/home"));

        });
    });
};

export const signIn = (email, password, stateCheck, isFreeUser = false) => {
  return async (dispatch) => {
    //Validation
    if (email === "" || password === "") {
      alert("必須項目が未入力です");
      return false;
    }

    auth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        const user = result.user;
        if (user) {
          const uid = user.uid;

          login(uid, dispatch, "email", isFreeUser);
        }
      })
      .catch((error) => {
        if (error.code === "auth/account-exists-with-different-credential") {
          alert(
            error.email +
            "こちらのメールアドレスを使用したSNSで既にアカウントを作成されています。同一のメールアドレスを使用しているSNSでログインしてください。"
          );
        } else {
          alert("メールアドレスもしくはパスワードが違います。");
        }
      });
  };
};

export const resetPassword = (email) => {
  return async (dispatch) => {
    if (email === "") {
      alert("必須項目が未入力です");
      return false;
    } else {
      auth
        .sendPasswordResetEmail(email)
        .then(() => {
          alert(
            "入力されたアドレスにパスワードリセット用のメールをお送りしました。"
          );
          dispatch(push("/signin"));
        })
        .catch(() => {
          alert("パスワードリセットに失敗しました。");
        });
    }
  };
};

const createNewUser = (dispatch, result, providerName, email = null) => {
  var user = result.user;
  // console.log("new user")
  // console.log(user)
  const uid = user.uid;
  const timestamp = FirebaseTimestamp.now();
  const playedTimeInit = FirebaseTimestamp.fromDate(new Date("2021/01/01"));
  if (email === null) {
    email = result.additionalUserInfo.profile.email;
  }
  // const email = result.additionalUserInfo.profile.email
  // const providerName = result.additionalUserInfo.providerId

  const ref = InteractiveRef.doc();
  const qrId = ref.id;

  const ProviderRef = UserRef.doc(uid).collection("Provider");
  const pref = ProviderRef.doc();
  const providerId = pref.id;

  // console.log("create InteractiveRef")
  const InteractiveNaitalRef = UserRef.doc(uid).collection("Interactive");
  const Intref = InteractiveNaitalRef.doc();
  const intId = Intref.id;

  // console.log("create Interactive Initial data")
  const InteractiveInitialData = {
    createTime: timestamp,
    expireTime: "",
    uId: uid,
    contentId: ""
  }
  const UserInteractivelInitialData = {
    contentId: "c00000",
    createTime: timestamp,
    d01: "",
    lastTime: timestamp,
    p01: "",
    p02: "",
    p03: "",
  }

  const UserInitialData = {
    permission: 0,
    createTime: timestamp,
    displayName: "",
    langage: "Jp",
    lastTime: timestamp,
    // qrId: qrId,
    uId: uid,
  };

  const ProviderInitialData = {
    createTime: timestamp,
    displayName: "",
    lastTime: timestamp,
    providerId: providerId,
    providerName: providerName,
    uId: uid,
  };

  const UserDatasInitialData = {
    birth: "",
    createTime: timestamp,
    gender: "",
    lastTime: timestamp,
    prefecture: "",
    email: email,
    birthNumber: "",
    tall: "",
    weight: "",
    distortion: "",
    skeleton: "",
  };

  // const PurchaseInitialData = {
  //   PurchaseId: "",
  // };

  UserRef.doc(uid).set(UserInitialData);

  UserRef.doc(uid)
    .collection("Provider")
    .doc(providerId)
    .set(ProviderInitialData);
  UserRef.doc(uid).collection("Datas").doc(uid).set(UserDatasInitialData);
  // UserRef.doc(uid).collection("Purchase").doc(uid).set(PurchaseInitialData);
  UserRef.doc(uid).collection("Interactive").doc(intId).set(InteractiveInitialData);
  db.collection('Interactive').doc(intId).set(UserInteractivelInitialData)

  db.collection("User")
    .doc(uid)
    .get()
    .then((snapshot) => {
      dispatch(
        signInAction({
          isSignedIn: true,
          uid: uid,
          displayName: "",
          birth: "",
          gender: "",
          prefecture: "",
          purchaseId: "",
          email: email,
          providerName: providerName,
          tall: "",
          weight: "",
          distortion: "",
          skeleton: "",
        })
      );
      dispatch(push("/mypage/edit"));

    });
};

export const signUp = (email, password, confirmPassword, stateCheck = false) => {
  return async (dispatch) => {
    //Validation
    if (
      email === "" ||
      password === "" ||
      confirmPassword === "" ||
      email === undefined ||
      password === undefined ||
      confirmPassword === undefined
    ) {
      // console.log("validation")
      alert("必須項目が未入力です。");
      return false;
    }

    // if (!stateCheck || stateCheck === undefined) {
    //   alert("アカウントを登録するには規約に同意が必要です。");
    //   return false;
    // }
    if (password !== confirmPassword) {
      alert("パスワードが一致しません。もう1度お試しください。");
      return false;
    }

    return auth
      .createUserWithEmailAndPassword(email, password)
      .then((result) => {
        createNewUser(dispatch, result, "email", email);
      });
  };
};

export const signInWithProviderFacebook = () => {
  return async (dispatch) => {
    var provider = new firebase.auth.FacebookAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        // console.log(result)
        if (!result.additionalUserInfo.isNewUser) {
          // console.log("already created user")
          /** @type {firebase.auth.OAuthCredential} */
          const credential = result.credential;
          const uid = result.user.uid;
          login(uid, dispatch, "Facebook");
        } else {
          createNewUser(dispatch, result, "Facebook");
        }
      })
      .catch((error) => {
        if (error.code === "auth/account-exists-with-different-credential") {
          alert(
            error.email +
            "こちらのメールアドレスで既にアカウントを作成されています。「メールアドレスでログインする」からログインをお試しください。"
          );
        }
        dispatch(push("/signin"));

      });
  };
};

export const signInWithProviderTwitter = () => {
  return async (dispatch) => {
    // Start a sign in process for an unauthenticated user.
    var provider = new firebase.auth.TwitterAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        // console.log(result)
        if (!result.additionalUserInfo.isNewUser) {
          // console.log("already created user")
          /** @type {firebase.auth.OAuthCredential} */
          const credential = result.credential;
          const uid = result.user.uid;
          login(uid, dispatch, "Twitter");
        } else {
          createNewUser(dispatch, result, "Twitter");
        }
        // ...
      })
      .catch((error) => {
        if (error.code === "auth/account-exists-with-different-credential") {
          alert(
            error.email +
            "こちらのメールアドレスで既にアカウントを作成されています。「メールアドレスでログインする」からログインをお試しください。"
          );
        }
        dispatch(push("/signin"));
      });
  };
};

export const signInWithProviderGoogle = () => {
  return async (dispatch) => {
    // Start a sign in process for an unauthenticated user.
    var provider = new firebase.auth.GoogleAuthProvider();
    await firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        // console.log(result)
        if (!result.additionalUserInfo.isNewUser) {
          // console.log("already created user")
          /** @type {firebase.auth.OAuthCredential} */
          const credential = result.credential;
          const uid = result.user.uid;
          login(uid, dispatch, "google");
        } else {
          createNewUser(dispatch, result, "google");
        }
        // ...
      })
      .catch((error) => {
        if (error.code === "auth/account-exists-with-different-credential") {
          alert(
            error.email +
            "こちらのメールアドレスで既にアカウントを作成されています。「メールアドレスでログインする」からログインをお試しください。"
          );
        }
        dispatch(push("/signin"));
      });
  };
};

const keisan = (t) => {
  // console.log("t=" + t)
  if (
    parseInt(t) < 10 ||
    parseInt(t) == 11 ||
    parseInt(t) == 22 ||
    parseInt(t) == 33
  ) {
    return t;
  } else {
    var sum = 0;
    for (var i = 0; i < t.length; i++) {
      sum = sum + parseInt(t.substr(i, 1));
    }
    return keisan(sum.toString());
  }
};

export const updatePersonalData = (displayName, email, gender, birthYear, birthMonth, birthDate, prefecture, uid, studentNum = false) => {

  return async (dispatch) => {
    //Validation
    if (displayName === "" || email === "" || gender === "" || birthYear === "" || birthMonth === "" || birthDate === "" || prefecture === "" || studentNum === "" || displayName === undefined || email === undefined || gender === undefined || birthYear === undefined || birthMonth === undefined || birthDate === undefined || prefecture === undefined || studentNum === undefined) {
      alert("必須項目が未入力です")
      return false
    }

    const birthday = FirebaseTimestamp.fromDate(new Date(birthYear + '/' + birthMonth + '/' + birthDate + ' ' + "12" + ':' + "00" + ':00')).toDate();
    const timestamp = FirebaseTimestamp.now()
    let strYear = birthYear.toString()
    let strMonth = birthMonth.toString()
    let strDate = birthDate.toString()
    if (birthMonth <= 10) {
      strMonth = "0" + strMonth
    }
    if (birthDate <= 10) {
      strDate = "0" + strDate
    }
    const t = strYear + strMonth + strDate
    const birthday_str = keisan(t)

    let tall = "";
    let weight = "";
    let distortion = "";
    let skeleton = "";


    UserRef.doc(uid)
      .collection("Datas")
      .doc(uid)
      .get()
      .then((snapshotPhis) => {
        const dataPhis = snapshotPhis.data();
        tall = dataPhis.tall;
        weight = dataPhis.weight;
        distortion = dataPhis.distortion;
        skeleton = dataPhis.skeleton;
      });

    db.collection('User').doc(uid).collection('Datas').get()
      .then(snapshot => {

        snapshot.forEach(function (doc) {
          const phisicals = db.collection('User').doc(uid).collection('Datas').doc(doc.id)
          dispatch(updateAction({
            displayName: displayName,
            birth: birthday,
            gender: gender,
            prefecture: prefecture,
            email: email,
            tall: tall,
            weight: weight,
            distortion: distortion,
            skeleton: skeleton,
            studentNum: studentNum,

          }))

          phisicals.update({
            birth: birthday,
            gender: gender,
            lastTime: timestamp,
            prefecture: prefecture,
            birthNumber: birthday_str,
            email: email,
            tall: tall,
            weight: weight,
            distortion: distortion,
            skeleton: skeleton,
            studentNum: studentNum
          })
        })
      })


    db.collection('User').doc(uid).collection('Provider').get()
      .then(snapshot => {
        snapshot.forEach(function (doc) {
          const provider = db.collection('User').doc(uid).collection('Provider').doc(doc.id)
          provider.update({
            displayName: displayName,
            lastTime: timestamp
          })
        })
      })
    const user = db.collection('User').doc(uid)
    user.update({
      displayName: displayName,
      lastTime: timestamp
    })

    dispatch(push("/mypage/edited"));
  }
}





export const isPlayed = (uid) => {
  return async (dispatch) => { };
};

export const deleteAccount = ({ uid, password, email }) => {
  return async (dispatch) => {
    const user = firebase.auth().currentUser;
    await user.delete().then(() => {
      alert("アカウントの削除が完了しました");
      console.log("アカウント削除")
      dispatch(signOutAction());
      dispatch(push("/"));
    }).catch((error) => {
      console.log(error)
      alert("メールアドレスもしくはパスワードが違います。");
      // An error ocurred
      // ...
    });

    // await UserRef.doc(uid)
    //   .delete()
    //   .then(() => {
    //     console.log("delete firebase auth user")
    //     user.delete().then(() => {
    //       console.log("delete firestore user")
    //       dispatch(signOutAction());
    //       dispatch(push("/signin"));
    //     });
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };
};

export const signOut = () => {
  return async (dispatch) => {
    auth.signOut().then(() => {
      dispatch(signOutAction());
      dispatch(push("/"));

    });
  };
};
