import { motion } from 'framer-motion';

const Motion = (props) => {
	return (
		<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.2 }}>
			{props.children}
		</motion.div>
	);
};

export default Motion;
