import React from 'react';
import { Route, Switch } from 'react-router';
import {
    SignIn, Home, Start, EmailSignUp, EmailSignIn,
    Reset, EditParsonalData, EditedPersonalData, MyPage, Qr, ConfirmDelete, Result, Form, EmailSignInDelete, SignInDelete
} from './templates';

import Auth from './Auth';

const Router = () => {

    return (
        <Switch>
            <Route exact path={"/"} component={Start} />
            <Route exact path={"/email-signup"} component={EmailSignUp} />
            <Route exact path={"/signin"} component={SignIn} />
            <Route exact path={"/email-login"} component={EmailSignIn} />
            <Route exact path={"/email-login-delete"} component={EmailSignInDelete} />
            <Route exact path={"/signin/reset"} component={Reset} />
            <Route exact path={"/signin/delete"} component={SignInDelete} />

            <Auth>
                <Route exact path={"/home"} component={Home} />
                <Route exact path={"/result"} component={Result} />
                <Route exact path={"/form"} component={Form} />
                <Route exact path={"/qr"} component={Qr} />
                <Route exact path={"/mypage/edit"} component={EditParsonalData} />
                <Route exact path={"/mypage/edited"} component={EditedPersonalData} />
                <Route exact path={"/confirm-delete"} component={ConfirmDelete} />
                {/**********  free *********/}
            </Auth>

        </Switch>
    )
}


export default Router;