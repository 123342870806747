import React from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import cn from "../css/contentsCard.module.scss"

import { PrimaryButton, Footer, FooterNav } from "./";
import { openQrPage } from "../../reducks/interactives/operations";

const ContentsCard = (props) => {

  const dispatch = useDispatch();
  return (
    <div className={cn.cardWrap + " " + "gl t-wh"}>
      <div className={cn.inner + " " + ""}>

        <h1>{props.mainText}</h1>

        <PrimaryButton
          label={"「専用コード」を表示する"}
          onClick={() => dispatch(openQrPage(props.qrText, props.uid))}
        />
        <div className="module-spacer--extra-extra-small" />
        {/* <PrimaryButton
          label={"結果を見る"}
          onClick={() => dispatch(push(props.resultURL))}
          disabled={props.isDisabled}
        /> */}

        <div className={cn.JPtextSS}>
          専用コードを端末にかざして体験をはじめましょう
        </div>
      </div>
    </div>
  );
};
export default ContentsCard;
