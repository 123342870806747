export const FETCH_INTERACTIVES = "FETCH_INTERACTIVES";
export const fetchInteractivesAction = (interactives) => {
    return {
        type: "FETCH_INTERACTIVES",
        payload:interactives
        }
    }

export const CREATE_INTERACTIVES = "CREATE_INTERACTIVES";
export const createInteractivesAction = (interactives) => {
    return {
        type: "CREATE_INTERACTIVES",
        payload:interactives
        }
    }
