import React, { useCallback, useState } from 'react';
import { push } from 'connected-react-router';
import cn from "../assets/sass/start.module.scss";
import { useDispatch } from 'react-redux';
import { PrimaryButton } from '../components/UIkit';
import { signIn } from '../reducks/users/operations';

import CheckboxInput from "../components/form/CheckboxInput";
import TextInput from "../components/form/TextInput";
import form from "../json/formData.json";


const EmailSignIn = () => {

	const dispatch = useDispatch()
	const [email, setEmail] = useState(""),
		[password, setPassword] = useState(""),
		[stateCheck, setStateCheck] = useState("");

	const isDisable =
		email !== "" &&
		password !== ""

	return (
		<div className={cn.signin + " " + "t-wh tex-c"}>
			<div className={cn.inner + " " + "tra-c wid-100"}>

				<div className={cn.title}>メールアドレスでサインインする</div>

				<div className={cn.textInput + " " + "mar-b1"}>
					<TextInput id={form.fields[0]} setValue={setEmail} />
				</div>

				<TextInput id={form.fields[1]} setValue={setPassword} />


				<div className={cn.linkWrap + " " + ""}>
					<div className={cn.link} onClick={() => dispatch(push('/signin/reset'))}>パスワードをお忘れの方はこちら</div>
					<div className={cn.link} onClick={() => dispatch(push('/email-signup'))}>メールアドレスでアカウントを作成する方はこちら</div>
				</div>
				{/* <a
					className={cn.linkSub}
					href="https://naked.co.jp/termsofservice/"
					target="_blank"
				>
					利用規約を見る
				</a>

				<a
					className={cn.linkSub}
					href="https://naked.co.jp/privacypolicy/"
					target="_blank"
				>
					プライバシーポリシーを見る
				</a>

				<div className="center tex-c">
					<CheckboxInput
						id={"規約に同意する"}
						setValue={setStateCheck}
						value={stateCheck}
					/>
				</div> */}
				<div className="center mar-t05">
					<PrimaryButton
						label={"サインイン"}
						disabled={!isDisable}
						onClick={() => dispatch(signIn(email, password, stateCheck))}
					/>
				</div>

				<div className={cn.mailLogin} onClick={() => dispatch(push('/signin'))}>SNSアカウントでサインインする</div>

			</div>

		</div>
	)
}

export default EmailSignIn