import { push } from 'connected-react-router';
import React, { useCallback, useState, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserId, getDisplayName } from '../reducks/users/selectors';
import { onAnsweredQuestionnaire, setToDatabase } from '../reducks/interactives/operations';
import { PrimaryButton } from '../components/UIkit';
import axios from 'axios'
import cn from "../assets/sass/start.module.scss";
import form from "../json/formDataQ.json";

import RadioInput from "../components/form/RadioInput";
import CheckboxInput from "../components/form/CheckboxInput";
import DropdownInput from "../components/form/DropdownInput";
import TextInput from "../components/form/TextInput";

const From = () => {
	const dispatch = useDispatch();
	const selector = useSelector((state => state));
	const uid = getUserId(selector)
	const [job, setJob] = useState("");
	const [know, setKnow] = useState("");
	const [comment, setComment] = useState("");


	const onSubmit = async (e) => {
		e.preventDefault();

		const formData = new FormData();

		formData.append("entry.1824743837", job ? job : "");
		formData.append("entry.1376344433", know ? know : "");
		formData.append("entry.614677774", comment ? comment : "");

		await axios({
			url: `https://docs.google.com/forms/d/e/1FAIpQLSe_Z_oQ2cEwAhwqCQBe0ciGn0F094FPv6dKI_yH9lwqrrNOrQ/formResponse`,
			method: "post",
			data: formData,
			responseType: "json",
		})
			.then(() => {
				console.log("ok")
				dispatch(setToDatabase(uid
				))
			})
			.catch((err) => {
				console.log("ng")
				dispatch(setToDatabase(uid
				))
			});
	};

	const isDisable =
		job !== "" &&
		know !== ""

	return (
		<div className={cn.signin + " " + "t-wh mid_wrap"}>
			<div className={cn.edit + " " + ""}>

				<div className={cn.wrap + " " + "tex-c t-wh mar-b2"}>
					<div className={cn.title}>アンケートにご協力ください</div>
				</div>
				<form onSubmit={onSubmit}>
					<div className={cn.drop + " " + ""}>
						<DropdownInput
							id={form.fields[0]}
							setValue={setJob}
							required={true}
							value={job}
						/>
					</div>
					<div className="module-spacer--medium" />
					<div className={cn.radio + " " + ""}>
						<RadioInput
							id={form.fields[1]}
							setValue={setKnow}
							required={"required"}
						/>
					</div>
					<div className="module-spacer--small" />
					<div className={cn.text + " " + ""}>
						<TextInput
							id={form.fields[2]}
							setValue={setComment}
							row="4"
						/>
					</div>
					<div className="module-spacer--medium" />
					{/* <input type="submit" value="送信する" /> */}
					<div className={cn.buttonWrap + " " + "tex-c"}>
						<button
							className={`${cn.submitButton} ${isDisable ? cn.active : ""}`}
							type="submit"
						>
							<p
								className={`${cn.submitLabel} ${isDisable ? "" : cn.active}`}
							>
								必須項目を入力してください
							</p>
							<p
								className={`${cn.submitLabel} ${isDisable ? cn.active : ""}`}
							>
								結果を見る
							</p>
						</button>
					</div>
				</form>
			</div>
		</div>
	)
}

export default From