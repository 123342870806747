import { useState, useRef, useEffect, memo, useCallback } from "react";
import ReactDOM from "react-dom";
import cn from "../css/utilityModal.module.scss";


const Modal = memo((props) => {
  const id = props.id;
  const closeLabel = props.closeLabel;
  const [show, setShow] = useState(props.defaultShow);

  const handleOn = useCallback(() => {
    setShow(true);
  }, [show]);

  useEffect(() => {
    const body = document.body;
    if (show == true) {
      const modal = document.getElementById(id);
      modal.scrollTo(0, 0);
      body.classList.add("noScroll");
    } else {
      body.classList.remove("noScroll");
      // setTimeout(() => {



      // }, 300);
    }
  }, [show]);
  return (
    <>
      {(() => {
        if (props.click) {
          return <div className="pos-a-area pointer" onClick={handleOn}></div>;
        }
      })()}

      {ReactDOM.createPortal(
        <div
          id={id}
          // onClick={() => setShow(false)}
          className={`${cn.modalWrap} ${show ? cn.active : ""} fle-c`}
        >
          <div className={cn.modal + " " + "modalInner"}>
            {props.children}
            <div onClick={() => setShow(false)} className={cn.close + " " + "tex-c close"}>
              {closeLabel ? closeLabel : "閉じる"}
            </div>
          </div>
        </div>,
        document.getElementById("portal")
      )}
    </>
  );
});
export default Modal;
