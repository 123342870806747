import React from "react";

import cn from "../css/programCard.module.scss"
const ProgramPoint = () => {

    return (
        <div className={cn.programPoint}>
            <div className={cn.JPtext}>プログラムの効果ポイント</div>
            <div className="module-spacer--small" />
            <section className="c-section-wrapin gl">
                <div className={cn.pointsWrap}>
                    <div className={cn.points}>肩こり改善</div>
                    <div className={cn.points}>腰痛改善</div>
                    <div className={cn.points}>猫背改善</div>
                    <div className={cn.points}>後姿美人</div>
                    <div className={cn.points}>代謝アップ</div>
                </div>
            </section>
        </div>
    )
}
export default ProgramPoint