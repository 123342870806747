import React, { useCallback, useState, memo } from "react";
import { useDispatch } from "react-redux";
import { updatePersonalData, signOut } from "../reducks/users/operations";
import Motion from "../components/UIkit/motion";
import { useSelector } from "react-redux";
import {
  getBirth,
  getDisplayName,
  getGender,
  getUserId,
  getPrefecture,
  getEmail,
  getStudentNum
} from "../reducks/users/selectors";
import { push } from "connected-react-router";
import cn from "../assets/sass/start.module.scss";

import RadioInput from "../components/form/RadioInput";
import DropdownInput from "../components/form/DropdownInput";
import TextInput from "../components/form/TextInput";
import form from "../json/formData.json";
import { PrimaryButton } from '../components/UIkit';
import { getYear, getMonth, getDate, format } from "date-fns";

const EditPersonalData = memo(() => {

  const selector = useSelector((state) => state);
  const uid = getUserId(selector);
  const displayNameInit = getDisplayName(selector);
  const emailInit = getEmail(selector);
  const genderInit = getGender(selector);
  const birth = getBirth(selector);
  const studentNumInit = getStudentNum(selector);
  const prefectureInit = getPrefecture(selector);

  const birthInit = new Date(birth)
  let initYear = "";
  let initMonth = "";
  let initDate = "";

  if (birthInit) {
    initYear = getYear(birthInit);
    initMonth = getMonth(birthInit) + 1;
    initDate = getDate(birthInit);
  }


  const dispatch = useDispatch();
  const [displayName, setdisplayName] = useState(displayNameInit),
    [email, setEmail] = useState(emailInit),
    [gender, setGender] = useState(genderInit),
    [prefecture, setPrefecture] = useState(prefectureInit),
    [birthYear, setBirthYear] = useState(initYear),
    [birthMonth, setBirthMonth] = useState(initMonth),
    [birthDate, setBirthDate] = useState(initDate),
    [studentNum, setStudentNum] = useState(studentNumInit)

  const [studentNumVal, setStudentNumVal] = useState(false)

  const isDisable =
    displayName !== "" &&
    email !== "" &&
    gender !== "" &&
    studentNum !== "" &&
    birthYear !== "" &&
    birthMonth !== "" &&
    birthDate !== "" &&
    prefecture !== "" &&
    studentNumVal !== false;


  return (
    <div className={cn.root}>
      <Motion>
        <div className={cn.signin + " " + "t-wh mid_wrap"}>
          <div className={cn.edit + " " + ""}>

            <div className={cn.wrap + " " + "tex-c t-wh mar-b2"}>
              <p className={"fon-1 mar-b02"}>
                あなたのユーザーID
              </p>
              <div className={cn.uid + " " + "fon-2"}>{uid}</div>
            </div>

            <div className={cn.wrap + " " + "tex-c t-wh mar-b2"}>
              <div className={cn.title}>あなたの情報を入力してください</div>
            </div>

            <div className={"mar-b1"}>
              <TextInput id={form.fields[9]} value={studentNum} setVal={setStudentNumVal} setValue={setStudentNum} inputProp={{ minLength: 9, pattern: "^[a-zA-Z0-9_]+$" }} required={true} errorText="英数字で9文字以上入力してください" />
            </div>

            <div className={"mar-b1"}>
              <TextInput id={form.fields[3]} value={displayName} setValue={setdisplayName} />
            </div>

            <div className={"mar-b1"}>
              <TextInput id={form.fields[0]} value={email} setValue={setEmail} />
            </div>

            <div className={cn.radio + " " + "mar-b1"}>
              <RadioInput
                id={form.fields[4]}
                setValue={setGender}
                required={"required"}
                value={gender}
              />
            </div>

            <p className={cn.label}>生年月日※必須</p>
            <div className={cn.birthday + " " + "mar-b1"}>
              <DropdownInput
                id={form.fields[5]}
                setValue={setBirthYear}
                setWidth="autoWidth"
                required={true}
                value={birthYear}
                label={"年"}
              />
              <DropdownInput
                id={form.fields[6]}
                setValue={setBirthMonth}
                setWidth="autoWidth"
                required={true}
                value={birthMonth}
                label={"月"}
              />
              <DropdownInput
                id={form.fields[7]}
                setValue={setBirthDate}
                setWidth="autoWidth"
                required={true}
                value={birthDate}
                label={"日"}
              />

            </div>

            <div className={cn.drop + " " + ""}>
              <DropdownInput
                id={form.fields[8]}
                setValue={setPrefecture}
                required={true}
                value={prefecture}
              />
            </div>

            <div className="tex-c mar-t3">
              <PrimaryButton
                disabled={!isDisable}
                label={"登録"}
                onClick={() =>
                  dispatch(
                    updatePersonalData(
                      displayName,
                      email,
                      gender,
                      birthYear,
                      birthMonth,
                      birthDate,
                      prefecture,
                      uid,
                      studentNum
                    )
                  )
                }
              />

              {displayNameInit === "" ? (
                <></>
              ) : (
                <>
                  <div
                    className={cn.JPtextS + " " + "mar-t3"}
                    onClick={() => dispatch(signOut())}
                  >
                    サインアウトする
                  </div>

                  <div className="tex-c mar-t3">
                    <div className="mid_wrap t-wh">
                      <div className="button fon-2" onClick={() => dispatch(push("/home"))}>
                        ホームに戻る
                      </div>
                    </div>
                  </div>
                  <div
                    className={cn.JPtextS + " " + "mar-t1"}
                    onClick={() => dispatch(push("/confirm-delete"))}
                  >
                    退会する
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Motion >
    </div >
  );
});

export default EditPersonalData;
