import React from "react";
import { ProgramPoint } from './index';
import { useDispatch } from 'react-redux';
import hyoujun from '../../assets/img/src/training_hyojun.png'
import katakori from '../../assets/img/src/training_katakori.png'
import youtsu from '../../assets/img/src/training_yotsu.png'
import cn from "../css/programCard.module.scss"

const ProgramCard = (props) => {
	let englishTitle = ''
	let japaneseTitle = ''
	let picSrc = props.picUrl
	let stTitle = ''
	let stText = ''
	let training_img = ''
	const program = props.program
	if (program == '2') {
		englishTitle = 'Back pain'
		japaneseTitle = '腰痛型'
		stTitle = '両手バンザイで左右運動ストレッチ！'
		stText = '両手をバンザイの状態で左右に身体を倒す動作を行います。身体が硬い方の範囲に合わせてゆっくりと大きく動かし、腰痛を改善しましょう。呼吸はゆっくり、止めずに行いましょう。'
		training_img = youtsu
	} else if (program == '3') {
		englishTitle = 'Stiff shoulder'
		japaneseTitle = '肩こり型'
		stTitle = '1日1~3分！<br/>肩すくめ運動で代謝アップ'
		stText = '肩をすくめて筋肉を緊張させ3秒キープし、その後ストンと肩を落として脱力。また肩甲骨をくっつけるように寄せて、戻す動作を繰り返し肩甲骨の動きを良くしましょう。全身の代謝も上がり、消費カロリーアップでダイエット効果も◎'
		training_img = katakori
	} else {
		englishTitle = 'standard'
		japaneseTitle = '標準型'
		stTitle = '足裏合わせの前屈ポーズで不調改善！'
		stText = '足の裏を合わせ、左右の膝の高さを合わせて息を吐きながら前屈をしましょう。膝の高さ（可動域）を合わせることにより、股関節の安定性向上に繋がります。'
		training_img = hyoujun
	}

	const dispatch = useDispatch()
	return (
		<div className={cn.program}>
			<div className={cn.dataWrap + " " + "flex fle-ac"}>
				<div className={cn.text + " " + ""}>
					<div className={cn.jptext}>あなたの身体は</div>
					<div className={cn.jptextBodyType}>{japaneseTitle}</div>
					<div className={cn.jptextDesu}>です</div>
				</div>
				<div className={cn.image + " " + ""}>
					<img className={cn.type} src={picSrc} />
				</div>
			</div>

			<div className={cn.egtitle}>
				NAKED SPOREV. WORK OUT
			</div>
			<div className="module-spacer--extra-small" />
			<div className={cn.jptitle} dangerouslySetInnerHTML={{
				__html: stTitle
			}}>
			</div>

			<img className={cn.training} src={training_img} />
			<div className={cn.jptext}>
				{stText}
			</div>

			<div className="module-spacer--medium" />
			<ProgramPoint />
		</div>
	)
}
export default ProgramCard