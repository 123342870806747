import React, { useState, useEffect, memo, useRef } from "react";
import TextField from "@mui/material/TextField";
import cn from "./form.module.scss";
import { styled } from "@mui/material/styles";

const TextFields = styled(TextField)`
  .MuiFilledInput-root {
    background-color: rgb(255 255 255 / 0.2);
    color: #fff;
    font-size: .8em;
    :before {
      border-bottom: 1px solid #fff;
    }
    :after {
      border-bottom: 1px solid #fff;
    }
    :hover {
      background-color: rgb(255 255 255 / 0.2);
    }
    &.Mui-focused {
      background-color: rgb(255 255 255 / 0.2);
    }
  }
  .MuiFormLabel-root {
    color: #fff;
   
  }
  .MuiInputLabel-root {
    font-size: .8em;
    &.Mui-focused {
      color: #fff;
    }
  }
`;

const TextInput = memo(({ id, value, setValue, setWidth, row, required, errorText, setVal, inputProp }) => {
  const [inputError, setInputError] = useState(false);
  const rowNum = row !== "" ? row : "1";
  const howRow = row ? true : false;
  const inputRef = useRef(null);
  const handleChange = () => {
    if (inputRef.current) {
      const ref = inputRef.current;
      if (required && !value || !ref.validity.valid) {
        setInputError(true);
        if (setVal) {
          setVal(false);
        }
      } else {
        setInputError(false);
        if (setVal) {
          setVal(true);
        }
      }
    }
  };

  return (
    <>
      <TextFields
        inputRef={inputRef}
        fullWidth={!setWidth}
        multiline={howRow}
        id="filled-basic"
        label={id.label}
        variant="filled"
        rows={rowNum}
        value={value}
        inputProps={inputProp}
        onChange={(e) => {
          setValue(e.target.value), handleChange;
        }}
        onBlur={handleChange}
        error={inputError}
      />
      <div className={`${cn.errorText} ${inputError && cn.active}`}>{errorText}</div>
    </>
  );
});
export default TextInput;
