import React from "react";
import { PrimaryButton } from './index';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { getDate, getMonth, getYear, format, parse, parseISO } from 'date-fns'

import ja from 'date-fns/locale/ja'
import cn from "../../assets/sass/result.module.scss"
import Modal from "../UIkit/utilityModal"
import { ResultDetailCard } from '../UIkit';
import dayjs from 'dayjs';

const ResultCard = (props) => {
	const id = props.id;
	const contentId = props.contentId
	const playDate = format(props.playDate, 'yyyy/MM/dd')
	const score = props.score
	const level = props.level
	const picUrl = props.picUrl
	const movUrl = props.movUrl
	const unit = props.unit
	const dispatch = useDispatch()
	let animal = ""
	if (contentId == 'c10201') {
		if (level == 1) animal = "Puma"
		if (level == 2) animal = "Orca"
		if (level == 3) animal = "Lion"
		if (level == 4) animal = "Kangaroo"
		if (level == 5) animal = "Wolf"
		if (level == 6) animal = "Horse"
		if (level == 7) animal = "Rabbit"
		if (level == 8) animal = "Cat"
		if (level == 9) animal = "Hawk"
	} else if (contentId == 'c10202') {
		if (level == 1) animal = "Elephant"
		if (level == 2) animal = "Hippopotamus"
		if (level == 3) animal = "Rhino"
		if (level == 4) animal = "Bear"
		if (level == 5) animal = "Bull"
		if (level == 6) animal = "Gorilla"
		if (level == 7) animal = "Boar"
		if (level == 8) animal = "Dog/Wolf"
		if (level == 9) animal = "Sheep"
	} else if (contentId == 'c10203') {
		if (level == 1) animal = "Hawk"
		if (level == 2) animal = "Horse"
		if (level == 3) animal = "Puma"
		if (level == 4) animal = "Kangaroo"
		if (level == 5) animal = "Orca"
		if (level == 6) animal = "Wolf"
		if (level == 7) animal = "Boar"
		if (level == 8) animal = "Hippopotamus"
		if (level == 9) animal = "Elephant"
	}

	return (
		<div className={cn.resultContent + " " + "t-wh"}>
			<div className={cn.inner + " " + "gl"}>

				<div className={cn.date + " " + ""}>{playDate}</div>

				<div className={cn.contentData + " " + ""}>
					<div className={cn.image + " " + ""}>
						<img className={cn.pic} src={picUrl} />
					</div>

					<div className={cn.rightTop + " " + ""}>
						<div className={cn.resultText}>YOUR SCORE</div>
						<div className={cn.scoreText}>{score}</div>
						<div className={cn.unit + " " + ""}>{unit}</div>
					</div>
					<div className={cn.rightBottom + " " + ""}>
						<div className={cn.resultText}>ANIMAL LEVEL</div>
						<div className={cn.scoreText}>{animal}</div>
					</div>
				</div>
				<div className={cn.buttonWrap + " " + "pos-r"}>
					<Modal id={id} click={true}>
						<ResultDetailCard
							contentId={contentId}
							playDate={playDate}
							score={score}
							unit={unit}
							level={level}
							picUrl={picUrl}
							movUrl={movUrl} />
					</Modal>
					<PrimaryButton
						label={"詳細を見る"}
					/>
				</div>
			</div>
		</div>
	)
}
export default ResultCard