import { createSelector } from 'reselect';

const usersSelector = (state) => state.users;

export const getIsSignedIn = createSelector(
    [usersSelector],
    state => state.isSignedIn
)

export const getQuestionnaire = createSelector(
    [usersSelector],
    state => state.isQuestionnaire
)

export const getUserId = createSelector(
    [usersSelector],
    state => state.uid
)

export const getUsername = createSelector(
    [usersSelector],
    state => state.username
)

export const getEmail = createSelector(
    [usersSelector],
    state => state.email
)

export const getQrcode = createSelector(
    [usersSelector],
    state => state.qrcode
)

export const getStudentNum = createSelector(
    [usersSelector],
    state => state.studentNum
)

export const getDisplayName = createSelector(
    [usersSelector],
    state => state.displayName
)

export const getProviderName = createSelector(
    [usersSelector],
    state => state.providerName
)

export const getBirth = createSelector(
    [usersSelector],
    state => state.birth
)
export const getAge = createSelector(
    [usersSelector],
    state => state.Age
)

export const getPrefecture = createSelector(
    [usersSelector],
    state => state.prefecture
)

export const getPurchaseId = createSelector(
    [usersSelector],
    state => state.purchaseId
)

export const getGender = createSelector(
    [usersSelector],
    state => state.gender
)

export const getTall = createSelector(
    [usersSelector],
    state => state.tall
)

export const getWeight = createSelector(
    [usersSelector],
    state => state.weight
)

export const getSkeleton = createSelector(
    [usersSelector],
    state => state.skeleton
)

export const getDistortion = createSelector(
    [usersSelector],
    state => state.distortion
)