import React, { useCallback, useState, useEffect, useLayoutEffect } from 'react';
import { FooterNav, ProgramCard, ResultCard, ResultCardResultCard, PrimaryButton, ResultDetailCard } from '../components/UIkit';
import cn from "../assets/sass/result.module.scss"
import Modal from "../components/UIkit/utilityModal"

import { FirebaseTimestamp, db } from "../firebase/index";
import { getUserId, getDisplayName, getBirth, getGender, getTall, getWeight, getSkeleton, getDistortion } from '../reducks/users/selectors';
import { getInteractives } from '../reducks/interactives/selectors';
import { fetchInteractives, isAnsweredQuestionnaire } from '../reducks/interactives/operations';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import logo from "../assets/img/src/logo_sporev.svg";
import 'fontsource-roboto';
import { getYear, getMonth, getDate, format } from "date-fns";
import {
	HeaderSNS,
} from "../components/UIkit";
import PersonIcon from '@mui/icons-material/Person';


const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const UserRef = db.collection('User')


const Result = () => {
	const selector = useSelector((state => state));
	const uid = getUserId(selector)
	const dispName = getDisplayName(selector)
	const weight = getWeight(selector)
	const height = getTall(selector)
	const birth = getBirth(selector);
	const gender = getGender(selector)
	const skeleton = getSkeleton(selector)
	const distortion = getDistortion(selector)
	const dispatch = useDispatch();
	const [interactives, setInteractives] = useState([]);

	const [resultVpj, setResultVpj] = useState([]);
	const [resultFbp, setResultFbp] = useState([]);
	const [resultSss, setResultSss] = useState([]);

	const [isPlayedVpj, setIsPlayedVpj] = useState(false);
	const [isPlayedFbp, setIsPlayedFbp] = useState(false);
	const [isPlayedSss, setIsPlayedSss] = useState(false);
	const [age, setAge] = useState("");

	const genderName = (() => {
		if (gender == "1") {
			return "男性"
		} else if (gender == "2") {
			return "女性"
		} else {
			return "その他"
		}
	})()



	const birthToAge = async (birth, dispatch) => {

		if (birth === "" || birth === undefined) {
			dispatch(push('/mypage/edit'))
		} else {

			const birthInit = new Date(birth);
			const today = FirebaseTimestamp.now().toDate()

			const birthYear = getYear(birthInit);
			const birthMonth = getMonth(birthInit) + 1;
			const birthDay = getDate(birthInit);

			let age = ''

			const year = await FirebaseTimestamp.now().toDate().getFullYear() - birthYear

			if (today.getMonth() - birthMonth > 0) age = year;
			if (today.getMonth() - birthMonth < 0) age = year - 1;
			if (today.getMonth() - birthMonth == 0) {
				if (today.getDate() - birthDay >= 0) {
					age = year
				} else {
					age = year - 1
				}

			}

			setAge(age)
		}

	}

	const getResultVpj = async (uid) => {
		await db
			.collection("User")
			.doc(uid)
			.collection("Interactive")
			.orderBy("createTime", "desc")
			.get()
			.then((snapshots) => {
				let tmpResult = [];
				snapshots.forEach((snapshot) => {
					const data = snapshot.data();
					if (data.contentId == "c10201" && data.isPlayed == true) {
						let uranaidata = snapshot.data();
						uranaidata.lastTime = uranaidata.lastTime.toDate();
						tmpResult.push(uranaidata);
						setIsPlayedVpj(true);
					}
				});
				setResultVpj(tmpResult);

			});
	};

	const getResultSss = async (uid) => {
		await db
			.collection("User")
			.doc(uid)
			.collection("Interactive")
			.orderBy("createTime", "desc")
			.get()
			.then((snapshots) => {
				let tmpResult = [];
				snapshots.forEach((snapshot) => {
					const data = snapshot.data();
					if (data.contentId == "c10203" && data.isPlayed == true) {
						let uranaidata = snapshot.data();
						uranaidata.lastTime = uranaidata.lastTime.toDate();
						tmpResult.push(uranaidata);
						setIsPlayedSss(true);
					}
				});

				setResultSss(tmpResult);
				return false;
			});
	};

	useLayoutEffect(() => {
		getResultVpj(uid);
		getResultSss(uid);
		birthToAge(birth, dispatch)
		dispatch(isAnsweredQuestionnaire(uid))
	}, []);

	// useEffect(() => {

	// }, [])


	// console.log(birth)

	return (
		<div className={cn.result}>
			<HeaderSNS />
			<button
				className={cn.myButton + " " + "myPageButton"}
				onClick={() => dispatch(push("/mypage/edit"))}
			>
				<PersonIcon fontSize="small" />
				マイページ
			</button>
			<div className={cn.logo + " " + ""}>
				<img src={logo} alt="" />
			</div>
			<div className="module-spacer--medium" />

			<div className="mid_wrap">
				<div className={cn.profWrap + " " + "gl t-wh"}>

					<div className={cn.dispName}>{dispName}</div>

					<div className={cn.dispDataWrap + " " + ""}>
						<div className={cn.leftTop + " " + ""}>
							<div className={cn.title}>Age</div>
							<div className={cn.data}>{age}</div>
							<div className={cn.unit}>歳</div>
						</div>
						<div className={cn.rightTop + " " + ""}>
							<div className={cn.title}>Gender</div>
							<div className={cn.data}>{genderName}</div>
						</div>
						<div className={cn.leftBottom + " " + ""}>
							<div className={cn.title}>Height</div>
							<div className={cn.data}>{height}</div>
							<div className={cn.unit}>cm</div>
						</div>
						<div className={cn.rightBottom + " " + ""}>
							{/* <div className={cn.title}>Weight</div>
							<div className={cn.data}>{weight}</div>
							<div className={cn.unit}>kg</div> */}
						</div>

						<div className={cn.skeleton + " " + ""}>
							<img className={cn.personalImg} src={skeleton} />
						</div>

					</div>

					<div className={cn.training + " " + ""}>
						<div className={cn.text}>あなたに合わせたトレーニングはこちら</div>

						<div className={cn.buttonWrap + " " + "pos-r"}>
							<Modal id="training" click={true}>
								<ProgramCard program={distortion} picUrl={skeleton} />
							</Modal>
							<PrimaryButton
								label={"トレーニング"}
							// onClick={handleClickOpenTraining}
							/>
						</div>

					</div>

				</div>
			</div>

			<div className="module-spacer--medium" />

			{resultVpj.length > 0 && (
				<section className={cn.contentsSection}>
					<div className={cn.sectionTitle}>
						<h1>VERTICAL POWER JUMP</h1>
					</div>
					{/* ここをfor文で回す scrol snap horizontal*/}

					<div className={cn.contentsWrap + " " + ""}>
						{resultVpj.map((e, key) => (
							<>
								<div key={key} className={cn.contents + " " + ""}>
									<ResultCard
										id={"modal_Vpj_" + key}
										contentId={e.contentId}
										playDate={e.lastTime}
										score={e.p01}
										unit="cm"
										level={e.p02}
										picUrl={e.d01}
										movUrl={e.d02}
									/>
								</div>
							</>
						))}
					</div>

				</section>
			)}
			{resultSss.length > 0 && (
				<section className={cn.contentsSection}>
					<div className={cn.sectionTitle}>
						<h1>SPEED SIDE STEPS</h1>
					</div>
					<div className={cn.contentsWrap + " " + ""}>
						{resultSss.map((e, key) => (
							<>
								<div key={key} className={cn.contents + " " + ""}>
									<ResultCard
										id={"modal_Sss_" + key}
										contentId={e.contentId}
										playDate={e.lastTime}
										score={e.p01}
										unit="times"
										level={e.p02}
										picUrl={e.d01}
										movUrl={e.d02}
									/>
								</div>
							</>
						))}
					</div>
				</section>
			)}

			<div className="tex-c mar-t3">
				<div className="mid_wrap t-wh">
					<div className="button fon-2" onClick={() => dispatch(push("/home"))}>
						ホームに戻る
					</div>
				</div>
			</div>

		</div >

	)


}
export default Result;